import $ from 'jquery';
import ScrollReveal from 'scrollreveal';

$(document).ready(function() {

  //scrollreveal
  ScrollReveal().reveal('.fade-in-bottom', {
    origin: 'bottom',
    distance: '30px',
    easing: 'cubic-bezier(.6,.26,.35,.74)',
    duration: 700
  });

  ScrollReveal().reveal('.fade-in-many', {
    origin: 'bottom',
    distance: '30px',
    easing: 'cubic-bezier(.6,.26,.35,.74)',
    duration: 700,
    interval: 500
  });

});
