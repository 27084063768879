import $ from 'jquery';
import 'what-input';
import libs from './lib/dependencies.js';

window.$ = $;
window.jQuery = $;
window.libs = libs;


//npm Modules
//import 'imagesloaded'
import 'lazysizes';

//Pixmill Scripts
import './px/hamburger.js'
import './px/opaque.js'
import './px/scrollreveal.js'



$(document).ready(function() {


});


//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import 'foundation-sites'
import './lib/foundation-explicit-pieces.js';

$(document).foundation();
