import $ from 'jquery'

$(document).ready(function () {
  //set class opaque on scroll
  $(document).on('scroll', function() {
      if($(this).scrollTop() > 5) {
        $('.h-fixed-top').addClass('opaque');
      } else {
        $('.h-fixed-top').removeClass('opaque');
      }
  });
});
